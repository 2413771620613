import { default as error_45testzmnJsbHLOfMeta } from "/opt/buildhome/repo/pages/error-test.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexPHe6oXUhAaMeta } from "/opt/buildhome/repo/pages/registration/index.vue?macro=true";
import { default as connectjslDrZH0vpMeta } from "/opt/buildhome/repo/pages/rope-admin/connect.vue?macro=true";
import { default as index0JSqTaTST3Meta } from "/opt/buildhome/repo/pages/rope-admin/index.vue?macro=true";
import { default as loginMhMbQspoGPMeta } from "/opt/buildhome/repo/pages/rope-admin/login.vue?macro=true";
import { default as person_45listZoknEYRBPbMeta } from "/opt/buildhome/repo/pages/rope-admin/person-list.vue?macro=true";
import { default as scannerTmaB5k7I9UMeta } from "/opt/buildhome/repo/pages/scanner.vue?macro=true";
export default [
  {
    name: "error-test",
    path: "/error-test",
    component: () => import("/opt/buildhome/repo/pages/error-test.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/opt/buildhome/repo/pages/registration/index.vue")
  },
  {
    name: "rope-admin-connect",
    path: "/rope-admin/connect",
    component: () => import("/opt/buildhome/repo/pages/rope-admin/connect.vue")
  },
  {
    name: "rope-admin",
    path: "/rope-admin",
    component: () => import("/opt/buildhome/repo/pages/rope-admin/index.vue")
  },
  {
    name: "rope-admin-login",
    path: "/rope-admin/login",
    component: () => import("/opt/buildhome/repo/pages/rope-admin/login.vue")
  },
  {
    name: "rope-admin-person-list",
    path: "/rope-admin/person-list",
    component: () => import("/opt/buildhome/repo/pages/rope-admin/person-list.vue")
  },
  {
    name: "scanner",
    path: "/scanner",
    meta: scannerTmaB5k7I9UMeta || {},
    component: () => import("/opt/buildhome/repo/pages/scanner.vue")
  }
]